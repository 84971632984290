import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import { RESET } from '@/lib/constants';
import { GetCustomOrderModelsForMakeResponse } from '@/lib/schema/models/types';
import { EVCompareMakeSelection } from '../EVCompareMakeSelection/EVCompareMakeSelection';
import {
  EVCompareModelHeader,
  EVDetailProps,
} from '../EVCompareModelHeader/EVCompareModelHeader';
import { EVCompareModelSelection } from '../EVCompareModelSelection/EVCompareModelSelection';
import {
  EVCompareTrimSelection,
  TrimListProps,
} from '../EVCompareTrimSelection/EVCompareTrimSelection';

const defaultEVDetails = {
  make: '',
  year: '',
  model: '',
  trim: '',
  MSRP: 0,
  imageUrl: '',
};

interface EVCompareModelProps {
  makes: string[];
  selectedEVDefaultDetails: EVDetailProps;
  handleMakeClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleMakeChange: () => void;
  handleBadgeClick: (year: string) => void;
  handleModelClick: (model: string, imageUrl: string, index: number) => void;
  handleReset?: () => void;
  makeEVOptions: GetCustomOrderModelsForMakeResponse;
  yearList: string[];
  activeModelIndex: number;
  trimList: TrimListProps[];
  handleTrimClick: (trim: string, MSRP: number, index: number) => void;
  activeTrimIndex: number;
  handleSelectEVClick?: () => void;
  modelHeader: string;
  modelButtonLabel: string;
  selectedFirstEVStyle?: string;
  selectedSecondEVStyle?: string;
  firstEVIconStyle?: string;
  secondEVIconStyle?: string;
  showComparisonDetailPage?: boolean;
  selectedFirstEVDetails?: EVDetailProps;
  selectedSecondEVDetails?: EVDetailProps;
  onCompleteFlow: () => Promise<void>;
  showReset: boolean;
  editEVAlertMessage?: string;
  selectedEditEVIndex?: number;
  showHeader?: boolean;
}

export const EVCompareModel: React.FC<EVCompareModelProps> = ({
  makes,
  selectedEVDefaultDetails,
  handleMakeClick,
  handleMakeChange,
  handleBadgeClick,
  handleModelClick,
  makeEVOptions,
  yearList,
  activeModelIndex,
  trimList,
  handleTrimClick,
  activeTrimIndex,
  handleSelectEVClick,
  handleReset,
  modelHeader,
  modelButtonLabel,
  selectedFirstEVStyle = '',
  selectedSecondEVStyle = '',
  secondEVIconStyle = '',
  firstEVIconStyle = '',
  showComparisonDetailPage,
  selectedFirstEVDetails = defaultEVDetails,
  selectedSecondEVDetails = defaultEVDetails,
  onCompleteFlow,
  showReset,
  editEVAlertMessage = '',
  selectedEditEVIndex,
  showHeader = true,
}) => {
  const atLastStep =
    showComparisonDetailPage || selectedEditEVIndex !== undefined;
  return (
    <>
      {showHeader && (
        <EVCompareModelHeader
          modelHeader={modelHeader}
          selectedFirstEVStyle={selectedFirstEVStyle}
          selectedSecondEVStyle={selectedSecondEVStyle}
          firstEVIconStyle={firstEVIconStyle}
          secondEVIconStyle={secondEVIconStyle}
          showComparisonDetailPage={showComparisonDetailPage}
          selectedFirstEVDetails={selectedFirstEVDetails}
          selectedSecondEVDetails={selectedSecondEVDetails}
        />
      )}
      <section className="item-start h-full w-full overflow-scroll">
        <EVCompareMakeSelection
          makes={makes}
          selectedMake={selectedEVDefaultDetails.make}
          handleMakeClick={handleMakeClick}
          handleMakeChange={handleMakeChange}
        />
        {selectedEVDefaultDetails.make !== '' && (
          <EVCompareModelSelection
            yearList={yearList}
            handleBadgeClick={handleBadgeClick}
            makeEVOptions={makeEVOptions}
            selectedYear={selectedEVDefaultDetails.year}
            selectedMake={selectedEVDefaultDetails.make}
            activeModelIndex={activeModelIndex}
            handleModelClick={handleModelClick}
            showComparisonDetailPage={showComparisonDetailPage}
            selectedEditEVIndex={selectedEditEVIndex}
          />
        )}
        {selectedEVDefaultDetails.model !== '' && (
          <EVCompareTrimSelection
            trimList={trimList}
            handleTrimClick={handleTrimClick}
            activeTrimIndex={activeTrimIndex}
          />
        )}
      </section>
      <section className="align-center absolute bottom-0 flex w-full justify-between overflow-hidden border-t border-neutral-300 bg-white pr-[30px] l:pr-[60px]">
        <Button
          variant={ButtonVariants.Default}
          onClick={handleReset}
          className={`!my-[15px] h-fit-content border-0 !px-0 !pb-0 !text-[clamp(0.75rem,1vw,1rem)] ${
            showReset ? '' : 'invisible'
          }`}
        >
          {RESET}
        </Button>
        <div className="flex w-[30%] items-center text-[clamp(0.5rem,1vw,0.75rem)] text-red-500 m:w-[40%]">
          {editEVAlertMessage}
        </div>
        <Button
          variant={ButtonVariants.newPrimary}
          onClick={atLastStep ? onCompleteFlow : handleSelectEVClick}
          className="!my-[15px] !py-[6px] !text-[clamp(0.75rem,1vw,1rem)]"
          disabled={selectedEVDefaultDetails.trim === ''}
        >
          {modelButtonLabel}
        </Button>
      </section>
    </>
  );
};
